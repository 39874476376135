import React from "react";
import { Link } from "react-router-dom";
import HtmlParser from "react-html-parser";
import { IMAGES_BASE_URL_ } from "src/config";

const BlogCard = ({ blog }) => {
    const safeDecode = (content) => {
        try {
            return decodeURIComponent(content);
        } catch (e) {
            console.warn('Decoding failed, using raw content');
            return content;
        }
    };

    const content = safeDecode(blog.content);
    const contentPreview = content.split('\n').slice(0, 4).join('\n');

    return (
        <div className="blog-card-wrapper itemCard">
            <Link to={{
                pathname: `/blog/${blog.id}`,
                state: blog,
            }}
                className="modern-blog-card">
                <div className="card-content-wrapper">
                    <h3 className="blog-card-title">{blog.title}</h3>

                    <div className="content-preview">
                        {HtmlParser(contentPreview)}
                    </div>
                </div>

                <div className="card-footer">
                    <div className="author-info">
                        <img
                            src={IMAGES_BASE_URL_ + 'blog/' + blog.path}
                            alt={blog.title}
                            className="author-image"
                        />
                        <div className="author-details">
                            <span className="author-name">
                                By {blog.createdBy ? `${blog.createdBy.firstName} ${blog.createdBy.lastName}` : 'Anonymous'}
                            </span>
                            <span className="post-date">
                                {new Date(blog.createdAt).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                })}
                            </span>
                        </div>
                    </div>

                    <div className="read-more-link">
                        Read More
                        <svg className="arrow-icon" viewBox="0 0 24 24">
                            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                        </svg>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default BlogCard;