import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from "react-i18next";
import { CAlert, CSpinner, CButton } from '@coreui/react';
import CIcon from "@coreui/icons-react";
import apiClient from 'src/components/apiClient';
import { isLogin } from "src/components/middleware/auth";
import TrainingCard from "../training/TrainingCard";
import OrderCard from './OrderCard';
import { MEDIA_URL } from 'src/config';
import { Link } from "react-router-dom";
import FavoriteContext from '../FavoriteContext';


const MyItems = (props) => {
    const { t } = useTranslation();

    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [results, setResults] = useState([]);
    const [errorImg, setErrorImg] = useState(false);
    const [title, setTitle] = useState('Ongoing courses');
    const [deleting, setdeleting] = useState(false);
    const { favorites, removeFromFavorites } = useContext(
        FavoriteContext
    );

    const removeFavorite = async (id) => {
        setdeleting(true);

        try {
            const success = await removeFromFavorites(id);

            if (success) {

                setResults((prevState) =>
                    prevState.filter(
                        (obj) => obj.id !== id
                    )
                );

                setdeleting(false);
            } else {

                setdeleting(false);
            }
        } catch (error) {
            console.error(error);
            setdeleting(false);
        }
    };
    const handleErrorImg = () => {
        setErrorImg(true)
    };
    useEffect(() => {
        if (isLogin) {
            apiClient.get("/member-api/get-orders")
                .then((result) => {
                    console.log('ffff', result);
                    setResults(result.data);

                    setIsLoaded(true);
                })
                .catch((error) => {
                    setIsLoaded(true);
                    setError('error')
                    console.log(error);
                });

        } else console.log('not loggged innn')
    }, []);

    const formatDuration = (minutes) => {
        const hrs = Math.floor(minutes / 60);
        const mins = minutes % 60;
        let result = '';
        if (hrs > 0) {
            result += `${hrs}h`;
        }
        if (mins > 0) {
            if (result) result += ' ';
            result += `${mins}min`;
        }
        return result;
    };
    const getFavs = () => {
        setIsLoaded(false);
        apiClient.get("/member-api/favorite/By_member")
            .then((result) => {
                console.log('ffff', result);
                setResults(result.data);
                setTitle('Favourite courses')
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError('error')
                //console.log(error);
            });
    }

    const getAll = () => {
        setIsLoaded(false);
        apiClient.get("/member-api/get-orders")
            .then((result) => {
                console.log('ffff', result);
                setResults(result.data);
                setTitle('Ongoing courses')
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError('error')
                //console.log(error);
            });
    }

    if (error) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div>Erreur : {error.message}</div>
                    </div>
                </main>
            </div>
        );
    } else if (!isLoaded) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div className='loadingMain'>
                            <CSpinner color="default" size="sm" />
                        </div>
                    </div>
                </main>
            </div>
        );
    } else

        return (
            <>
                <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%" }}>
                    <div className='myItemsPageHeading'>
                        <h3>{title} </h3>
                        <div className='coursesFilter'>
                            <span style={title == 'Ongoing courses' ? { color: '#26bbcd', fontWeight: '500' } : {}} onClick={getAll}>Ongoing</span>
                            <span style={title == 'Favourite courses' ? { color: '#26bbcd', fontWeight: '500' } : {}} onClick={getFavs}>Favorite</span>
                            <span >Complete</span>
                        </div>
                    </div>
                    <div className="element-grid">
                        {results.length > 0 ? (
                            // Split results into groups
                            (() => {
                                const total = results.length;
                                const remainder = total % 3;
                                const groups = [];
                                let startIndex = 0;

                                // Handle first group based on remainder
                                if (remainder === 1) {
                                    groups.push(results.slice(0, 1));
                                    startIndex = 1;
                                } else if (remainder === 2) {
                                    groups.push(results.slice(0, 2));
                                    startIndex = 2;
                                }

                                // Add remaining elements in groups of 3
                                for (let i = startIndex; i < total; i += 3) {
                                    groups.push(results.slice(i, i + 3));
                                }

                                return groups.map((group, groupIndex) => (
                                    <div
                                        key={groupIndex}
                                        className="grid-row"
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: `repeat(${group.length}, 1fr)`,
                                            gap: '2px',
                                            marginBottom: '30px'
                                        }}
                                    >
                                        {group.map((el) => (
                                            <div className={`myItemsPage element`} key={el.id}>
                                                {title == 'Favourite courses' && deleting ?
                                                    <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                                                    : title == 'Favourite courses' && !deleting ? <CButton className="remove_button remove_button2" onClick={() => removeFavorite(el.id)}>
                                                        <CIcon className='deleteFav' size={'5xl'} name={'cilTrash'} />
                                                    </CButton> : ''}
                                                <Link
                                                    className="myItemsProfile"
                                                    to={//props.status === 'completed'
                                                        el.training ?
                                                            `/course/${el.id}/${el.training.slug}/session`
                                                            : `/course/${el.id}/${el.slug}`}
                                                >

                                                    {el.demo ? <video className="mediaContainer" autoPlay="autoplay" controls muted >
                                                        <source src={MEDIA_URL + el.demo} type="video/mp4" />
                                                    </video>
                                                        : <div className="imgContainer mediaContainer">
                                                            {el.imageTrainings && el.imageTrainings.length > 0 ?
                                                                <img src={MEDIA_URL + el.imageTrainings[0].path} onError={handleErrorImg} />
                                                                : el.imageSessions && el.imageSessions.length > 0 ?
                                                                    <img src={MEDIA_URL + el.imageSessions[0].path} onError={handleErrorImg} />
                                                                    : <img className="defaultImg" src='/assets/images/logo_gdtc.png' onError={handleErrorImg} />
                                                            }
                                                        </div>
                                                    }
                                                    <div className='courseInfoContainer'>
                                                        <div className="progress-bar-container-out">
                                                            <span>progress {el.fullDuration && el.seenDuration ? Math.round((el.seenDuration / el.fullDuration) * 100) + '%' : '0%'} </span>
                                                            <div className="progress-bar-container">
                                                                <div
                                                                    className="progress-bar"
                                                                    style={{
                                                                        width: `${el.fullDuration && el.seenDuration ? Math.round((el.seenDuration / el.fullDuration) * 100) : '0'}%`
                                                                    }}
                                                                />
                                                            </div></div>

                                                        <strong className='itemTitle'>{el.title}</strong>
                                                        <strong className='itemTitle'>{formatDuration(el.fullDuration)}</strong>
                                                        {
                                                            /* el.trainer && el.trainer.profile ?
                                                            <img
                                                                className="itemTrainerImg itemTrainer"
                                                                src={MEDIA_URL + el.trainer.profile}
                                                                alt="avatar"

                                                            /> : */
                                                            el.trainer && <strong className='itemTrainer'>by {el.trainer.firstName && el.trainer.firstName} {el.trainer.lastName && el.trainer.lastName}</strong>}
                                                        {
                                                            /* el.training && el.training.trainer && el.training.trainer.profile ?
                                                            <img
                                                                className="itemTrainerImg itemTrainer"
                                                                src={MEDIA_URL + el.training.trainer.profile}
                                                                alt="avatar"

                                                            /> : */
                                                            (el.training && el.training.trainer) && <strong className='itemTrainer'>by {el.training.trainer.firstName && el.training.trainer.firstName} {el.training.trainer.lastName && el.training.trainer.lastName}</strong>}

                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ));
                            })()
                        ) : null}
                    </div>

                </div>
            </>
        )
}

export default MyItems
