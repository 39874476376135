import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
const Mentions = () => {
    const { t } = useTranslation();
    const [lang, setLang] = useState('en');
    const { i18n } = useTranslation();
    function changeLanguage(lang) {

        i18n.changeLanguage(lang);
        setLang(lang)
    }
    return (
        <>
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
                <div className='tempHeadingForRegister' style={{ marginTop: '-5%', marginBottom: '25px' }} >

                    <div className='desktopHeader' >
                        <nav >
                            <div className="w_mobilebgcover"></div>
                            <div className="w_nav">
                                <div data-collapse="medium" data-animation="default" data-duration="500" data-doc-height="1"
                                    data-w-id="392b72ab-2256-dd42-b8fd-e4ecdc60f83b" className="n_mainmobilenavbar w-nav">
                                    <div className="c_navbar_mobile w-container">
                                        {<Link to="#" aria-current="page"
                                            className="logo w-nav-brand w--current"><img
                                                src="/assets/images/logo_gdtc.png"
                                                alt="GDTC Logo " className="img_navlogo"
                                                style={{ position: 'relative', top: '-15px', height: '70px' }}
                                            />
                                        </Link>
                                        }

                                        <nav role="navigation" className="nav-menu w-nav-menu" id="nav"  >
                                            <div className="d_navleftside">
                                            </div>
                                            <div className="nav_menu_content">

                                            </div>

                                            <div className="d_mobilenavdivider"></div>
                                            <div className="d_navrightside">

                                                <div className='changeLang'>
                                                    <span onClick={(e) => changeLanguage('fr')}
                                                        className={`${lang == 'en' ? 'showLangFlag' : 'hideLangFlag'}  tl_nav_link cc_smallermargin cc_navmediulink `}>
                                                        Français  <img src={"/assets/images/fr.png"} className="flag" /></span>
                                                    <span onClick={(e) => changeLanguage('en')}
                                                        className={`${lang == 'fr' ? 'showLangFlag' : 'hideLangFlag'} tl_nav_link cc_smallermargin cc_navmediulink`}>
                                                        English <img src={"/assets/images/en.png"} className="flag" /></span>

                                                </div>


                                                <div data-w-id="515a474a-883e-6da8-0b48-9895f0629a36" className="nav_mobile"></div>
                                            </div>

                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <h3>
                    {t("Owner_of_GDTC")}
                </h3>
                <p>
                    {t("mentions1")}
                </p>
                <p>{t("mentions2")}
                </p>
                <h4>
                    {t("mentions3")}
                </h4>
                <p>
                    {t("site_internet")} <Link to="/">www.gdtc.ac</Link> {t("mentions4")}
                </p>
                <ul>
                    <li>
                        <strong> {t("mentions5")}:</strong> {t("mentions6")}
                    </li>

                    <li>
                        <strong> {t("mentions7")}:</strong> {t("mentions8")}
                    </li>
                </ul> <br />
                <p>
                    <h4>
                        {t("mentions9")}
                    </h4>
                    <ul>
                        <li>
                            <strong> {t("mentions10")}:</strong> {t("mentions11")} <Link to="/">www.gdtc.ac</Link> {t("mentions12")}
                        </li>

                        <li>
                            <strong> {t("mentions13")}:</strong> {t("mentions14")}
                        </li>
                    </ul>
                    <p>{t("mentions15")} <Link to="/">www.gdtc.ac</Link>, {t("mentions16")}</p>
                </p>
                <p>
                    <strong>
                        {t("mentions17")}
                    </strong> <br />
                    {t("mentions18")}
                </p>
                <p>{t("mentions19")} <Link to="/">www.gdtc.ac</Link>, {t("mentions20")}</p>
                <strong>{t("mentions21")}</strong><br />
                <p>{t("mentions22")} <Link to="/">www.gdtc.ac</Link>, {t("mentions23")}</p>
                <p>{t("mentions24")} </p>
                <strong>{t("mentions25")}</strong><br />
                <p>{t("site_internet")} <Link to="/">www.gdtc.ac</Link> {t("mentions26")} <Link to="/">www.gdtc.ac</Link>, {t("mentions27")}</p>

                <strong>{t("mentions28")}</strong><br />
                <p>{t("site_internet")} <Link to="/">www.gdtc.ac</Link> {t("mentions29")}</p>
                <br /><br />
                {/* 
                <p>
                    Le site Internet <Link to="/">www.gdtc.ac</Link> dans sa section GMTC global medical Training center a pour objectif d'informer les médecins du monde entier sur les avancées techniques et technologiques dans plusieurs spécialités médicale. Il renseigne également sur les activités scientifiques et les formations dispensées par l'équipe du GDTC.</p><p>
                    Les informations publiées sur ce site sont basées sur les données actuelles de la science médicale et de la littérature internationale. La participation régulière à des conférences et à des activités de formation continue, ainsi que l'expérience clinique et technique des responsables des publications sur place, garantissent que les contenus publiés sont scrupuleusement rigoureux et d'un haut niveau de conformité aux exigences scientifiques.</p><p>
                    Les informations publiées sur ww.dgtc.ac respectent également le code de déontologie en vigueur et adopté par le Conseil National de l'Ordre des Médecins de Tunisie, le respect des bons rapports de bourses est une règle.</p><p>
                    Le site Internet d'origine dont l'adresse était  <Link to="/">www.gdtc.ac</Link> est en ligne depuis avril 2020, son contenu et sa présentation sont continuellement améliorés.
                </p>
                <strong>
                    Publicité
                </strong>
                <p>Le site Internet du GDTC héberge des logos et des liens vers certaines sociétés ou sociétés ayant un intérêt commercial direct ou indirect dans le domaine dentaire (laboratoire dentaire, fabricant dentaire, société pharmaceutique, etc.). Ces derniers n'interviennent en aucune manière dans les publications et informations diffusées sur ce site, mais peuvent intervenir en tant que sponsor.</p><p>
                    Ces entreprises fournissent les équipements, matériels et outils destinés aux activités pédagogiques et d’apprentissage du GDTC. Les médecins visitant le site <Link to="/">www.gdtc.ac</Link> sont informés de l'existence d'autres alternatives et techniques pour l'exercice de la médecine  en plus de celles publiées sur le site ou apprises lors des formations dispensées par le personnel enseignant du GDTC.</p>
                <strong>
                    Utilisation informatique des données de connexion
                </strong>
                <p>
                    L'auteur de ce site s'engage sur l'honneur à respecter les conditions légales de confidentialité applicables en République Tunisienne en rapport avec la protection des données personnelles. Le site <Link to="/">www.gdtc.ac</Link> a été déclaré à l'INPDP et respecte la
                    <a href="http://www.inpdp.nat.tn/ressources/loi_2004.pdf" target="_blank"> Loi organique n° 2004-63 en date du 27 juillet 2004 portant sur la protection des données à caractère personnel</a>
                </p>
                <p>
                    Lorsque vous prenez contact avec nous, vos coordonnées sont enregistrées dans une base de données pour une durée indéterminée, et servent à établir des statistiques internes au GDTC et à vous envoyer des informations et des publications relatives à son activité. Vos coordonnées ne sont en aucun cas communiquées à des niveaux.
                </p>
                <strong>Propriété</strong>
                <p>Le site <Link to="/">www.gdtc.ac</Link> est un site conçu par Mr Haithem cherni , Ce site internet est la propriété exclusive de son concepteur en particulier, de toute compagnie financière ou industrielle, ainsi que de toute association  religieuse, syndicale ou politique. Les informations disponibles sur le site <Link to="/">www.gdtc.ac</Link> peuvent être visionné pour un usage privé. Elles ne peuvent être ni copiées, ni reproduites, ni transmises, ni distribuées, ni affichées, ni traduites sans la permission expresse du représentant habilité du site. Les photos présentes sur le site sont la propriété du GDTC.</p>
                <strong>
                    Hébergement
                </strong>
                <p>
                    Le site <Link to="/">www.gdtc.ac</Link> est hébergé chez DOZANE STUDIO.
                </p> */}
            </div>
            {/* <div className="w_commercial_alternating_cards cc_text_group_7" style={{"marginBottom": "10%"}}>
        <h2>{t("mention_legale_title")}</h2>

            <h3>{t("propriete_site_title")}</h3>
        <p>
            {t("propriete_p1")}
        </p>
        <p>
            {t("propriete_p2")}
        </p>
        <h3>{t("charte_titre")}</h3>
        <p>{t("site_internet")} <Link to="/">www.gdtc.ac</Link> {t("charte_p1")}</p>
        <p>{t("charte_p2")}</p>
        <p>{t("charte_p3")}</p>
        <p>{t("charte_p4_1")} <Link to="/">www.gdtc.ac</Link> {t("charte_p4_2")} </p>

        <h3>{t("pub_titre")}</h3>
        <p>{t("pub_p1")}</p>
        <p>{t("pub_p2_1")}  <Link to="/">www.gdtc.ac</Link> {t("pub_p2_2")}</p>
        <h3>{t("utilisation_info_titre")}</h3>
       <p>
           {t("utilisation_p1")}  <Link to="/">www.gdtc.ac </Link>
           {t("utilisation_p2")} <a href="http://www.inpdp.nat.tn/ressources/loi_2004.pdf" target="_blank">{t("link_prop")}</a>
       </p>
        <p>
            {t("utilisation_p3")}
        </p>
        <h3>{t("propriete_titre")}</h3>
        <p>{t("propriete_p")}</p>
        <h3>{t("hebergement_titre")}</h3>
        <p>{t("hebergement_p")}</p>
    </div>  */}
        </>
    )
}

export default Mentions
