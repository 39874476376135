import React, { Component } from 'react'
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import IndexFront from './components/FrontOffice/index'
import PublicRoute from './components/hooks/PublicRoute'
import GeneralProvider from './components/BackOffice/GeneralProvider'
import Soon from './components/FrontOffice/soon'
import trainerRegister from './components/FrontOffice/trainerRegister'
import RegisterM from './components/FrontOffice/member/Register'
import Mentions from './components/FrontOffice/presentation/Mentions'
//import { register } from './serviceWorker'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      // <HashRouter /*basename={"/test-front-548646"}*/>
      <GeneralProvider>
        <BrowserRouter >
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/852508268754/register" name="Register Page" render={(props) => <Register {...props} />} />
              <PublicRoute restricted={true} component={Login} path="/852508268754/login" exact />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route path="/852508268754" name="Home" render={(props) => <DefaultLayout {...props} />} />
              {/* <Route  path="/" render={props => <IndexFront {...this.props}/>} /> */}
              <Route path="/test-5436726732" render={props => <IndexFront {...this.props} />} />
              <PublicRoute component={trainerRegister} path="/join-us" exact />
              <PublicRoute component={RegisterM} path="/create" exact />
              <PublicRoute component={Mentions} path="/mentions-legales" exact />

              <Route exact path="/" component={Soon} />

            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </GeneralProvider>
      // </HashRouter>
    )
  }
}
export default App
