import React, { useEffect, useState, useContext } from "react";
import { API_BASE_URL } from "../../../config";
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../../reducers/actions';
import apiClient from "src/components/apiClient";
import {
  CButton,
  CSpinner
} from "@coreui/react";
import { MEDIA_URL, consol } from "../../../config";
import FavoriteContext from "../FavoriteContext";
import { isLogin } from "src/components/middleware/auth";
import TrainingCard from "../training/TrainingCard";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";


const Favorite = (props, state) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFav, setIsFav] = useState(null);
  const [isBeating, setBeating] = useState(false);
  const [modal, setModal] = useState(false);
  const [formations, setFormations] = useState([]);
  const [deleting, setdeleting] = useState(false);

  const { favorites, removeFromFavorites } = useContext(
    FavoriteContext
  );

  const removeFavorite = async (id) => {
    setdeleting(true);

    try {
      const success = await removeFromFavorites(id);

      if (success) {

        setFormations((prevState) =>
          prevState.filter(
            (obj) => obj.id !== id
          )
        );

        setdeleting(false);
      } else {

        setdeleting(false);
      }
    } catch (error) {
      console.error(error);
      setdeleting(false);
    }
  };

  useEffect(() => {
    // console.log('ch',checkFavorite(props.id) , favorites)
    if (favorites) {
      const formData = new FormData();
      //formData.append("user",   localStorage.getItem("id"));
      apiClient.get("/member-api/favorite/By_member")
        .then((result) => {
          console.log('fffflll', result);
          setFormations(result.data);

          setIsLoaded(true);
        })
        .catch((error) => {
          setIsLoaded(true);
          setError('error')
          //console.log(error);
        });

    }
  }, [favorites]);




  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className='loadingMain'>
              <CSpinner color="default" size="sm" />
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%", marginTop: "2" }}>
        <div className="row">
          {formations.length > 0 ? formations.map((formation, index) => {
            return (
              <div className="col-md-4 favPage" key={index} style={{ marginBottom: '20px', borderRadius: '50px' }} >
                {deleting ?
                  <CSpinner className="remove_button remove_buttonSpinner" color="default" size="sm" />
                  : <CButton className="remove_button remove_button2" onClick={() => removeFavorite(formation.id)}>
                    <CIcon className='deleteFav' size={'5xl'} name={'cilTrash'} />
                  </CButton>}

                <TrainingCard
                  path={formation.demo && formation.demo}
                  image={formation.imageTrainings && formation.imageTrainings.length > 0 ? formation.imageTrainings[0].path : ''}
                  title={formation.title}
                  formateur={formation.trainer ?
                    formation.trainer.firstName && formation.trainer.firstName + " " + formation.trainer.lastName
                    : ''}
                  createdAt={formation.createdAt}
                  updatedAt={formation.updatedAt}
                  sessions={formation.sessions}
                  id={formation.id}
                  language={formation.language}
                  medical={false}
                >

                </TrainingCard>
              </div>

            )
          })
            : t("no_favorites")
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  console.log(state)
  return {
    basketItems: state.basketItems
  };
}
export default connect(mapStateToProps, actions)(Favorite)