import React, { useState, useEffect, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { CCol } from "@coreui/react";
import { useTranslation } from "react-i18next";
import FavoriteContext from '../FrontOffice/FavoriteContext';

const Footer = () => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false)
    const location = useLocation();
    const [isMedicalPage, setisMedicalPage] = useState(false);

    const { pColor, sColor, tColor } = useContext(
        FavoriteContext
    );

    useEffect(() => {

        if (location.pathname.includes('medical-center')) setisMedicalPage(true);
        else setisMedicalPage(false);
    }, [location])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);


    return (
        <>
            {/* <div className='upIconWrapper'> */}
            <div className='upIcon' onClick={scrollToTop}
                style={{ display: visible ? 'block' : 'none' }} >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" /></svg>
            </div>


            <div className="f_mainfooter" style={{ backgroundColor: sColor }} >
                <div className="c_page">
                    <div className="row footerRow">
                        <CCol md="4" >
                            <h4>{isMedicalPage ? "GMTC" : "GDTC"} </h4>
                            <ul>
                                <li>
                                    {/* <a href='/about'>About</a> */}
                                    <Link to={isMedicalPage ? "/GMTC-medical-center/about" : "/about"}>{t("about")}</Link>
                                </li>
                                <li> <Link to='/#'>{t("careers")}</Link></li>
                                <li> <Link to='/certificates'>{t("professional_certificates")}</Link></li>
                                <li> <Link to='/certificates-master'>{t("mClass_certificates")}</Link></li>
                                <li> <Link to='/enterprise'>{t("for_company")}</Link></li>
                                <li> <Link to={isMedicalPage ? "/GMTC-medical-center/become-partner" : "/become-partner"}>{t("become_partner")}</Link></li>
                            </ul>
                        </CCol>
                        <CCol md="4" className='colWithSectionButton' >
                            <h4>Communauté {t("community")}</h4>
                            <ul>
                                <li> <Link to='/#'>{t("students")}</Link></li>
                                <li> <Link to={isMedicalPage ? "/GMTC-medical-center/partners" : "/partners"}>{t("partners")}</Link></li>
                                <li> <Link to='/blog'>Blog</Link></li>
                            </ul>
                            {isMedicalPage ?
                                <>
                                    <NavLink style={{ backgroundColor: pColor }} to="/" activeClassName="w--current" className="tl_nav_link importantLink medicalNavButton" >{t("Career_center")}</NavLink>

                                </>
                                :
                                <>
                                    <NavLink style={{ backgroundColor: pColor }} to="/GMTC-medical-center" activeClassName="w--current" className="tl_nav_link importantLink medicalNavButton" >{t("medical_canter")}</NavLink>
                                </>
                            }
                            <div className='bottomRow'>
                                <div className="w_sociallinks">
                                    <div className="w_sociallinks_inner">
                                        <a
                                            rel="noopener" role="img" aria-label="Facebook Link"
                                            href="https://www.facebook.com/GDTC-Global-Dental-Training-Center-106594057688123"
                                            target="_blank"
                                            className="lb_socialicon cc-fb w-inline-block"></a><a
                                                rel="noopener" role="img" aria-label="Tweeter Link" id="w-node-a81cc02ba82e-fd3ac0f6"
                                                href="#" target="_blank"
                                                className="lb_socialicon cc-twitter w-inline-block"></a><a
                                                    rel="noopener" role="img" aria-label="Youtube Link"
                                                    href="#" target="_blank"
                                                    className="lb_socialicon cc-yt w-inline-block"></a>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                        <CCol md="4" >
                            <h4>Plus</h4>
                            <ul>
                                <li> <Link to='/mentions-legales'>Conditions</Link></li>
                                <li> <Link to='/declaration-confidentialite'>{t("confidentiality")}</Link></li>
                                <li> <Link to='/#'>{t("help")}</Link></li>
                                <li> <Link to='/#'>{t("accessibility")}</Link></li>
                                <li> <Link to='/contact'>Contact</Link></li>
                                <li> <Link to='/#'>Articles</Link></li>
                            </ul>

                        </CCol>
                    </div>

                </div>
                {/* <div className="w_bottombar">
        <div className="c_page">
            <div className="w-layout-grid grid-11">
                <div  className="w_sociallinks">
                    <div className="w-layout-grid grid-33">
                        <a
                                rel="noopener" role="img" aria-label="Facebook Link"
                                href="https://www.facebook.com/GDTC-Global-Dental-Training-Center-106594057688123"
                                target="_blank"
                                className="lb_socialicon cc-fb w-inline-block"></a><a
                                rel="noopener" role="img" aria-label="Tweeter Link" id="w-node-a81cc02ba82e-fd3ac0f6"
                                href="#" target="_blank"
                                className="lb_socialicon cc-twitter w-inline-block"></a><a
                                rel="noopener" role="img" aria-label="Youtube Link"
                                href="#" target="_blank"
                                className="lb_socialicon cc-yt w-inline-block"></a>
                    </div>
                </div>

                <div className="sp_bottomfooterright w-layout-grid ">
                    <div className="text-block">Designed & developed by <a href="https://dozane-studio.com" target="_blank" style={{color:'#ffffff'}}
                                                                      >Dozane Studio</a><br/>©2020-{new Date().getFullYear()}
                    </div>
                    <div>
                        <Link to="/declaration-confidentialite" className="text-light" target="_blank"> Déclaration de
                            confidentialité</Link> |
                        <Link to="/mentions-legales" className="text-light" target="_blank"> Mentions légales</Link>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
            </div >
        </>
    )
}

export default Footer
