import React, { useState, useEffect, useContext } from "react";
import { CCol, CRow, CListGroupItem, CListGroup, CSpinner } from "@coreui/react";
import { IMAGES_BASE_URL, MEDIA_URL } from "../../../config";
import {
  Route,
  Switch,
  BrowserRouter,
  Link,
  HashRouter,
  NavLink
} from "react-router-dom";
import favorite from "./favorite";
import MyProfile from "./myProfile";
import MyItems from "./myItems";
import { logout } from "src/components/middleware/auth";
import { useTranslation } from "react-i18next";
import MyProfileMain from "./MyProfileMain";

function Profile(props) {
  const { t } = useTranslation();
  const [showSub, setshowSub] = useState(false);
  const [showMenu, setshowMenu] = useState(false);


  useEffect(() => {

    const section = document.querySelector('#profileSectionContentID');
    if (section) {
      if (window.innerWidth < 768) {
        const offset = section.getBoundingClientRect().top - 150;
        window.scrollBy({ top: offset, behavior: 'smooth' });
        // console.log(section.getBoundingClientRect().top , 'in 1');
      }

    }

  }, [props.location.pathname]);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          {/* <HashRouter> */}

          <React.Suspense>

            <CRow className="profilePageAll">
              <CCol sm="2" className="profileSideBar">
                <div className="c-avatar profile-avatar ">
                  <img
                    className="c-avatar-img"
                    src={
                      localStorage.getItem("profile") !== "undefined"
                        ? MEDIA_URL + localStorage.getItem("profile")
                        : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                    }
                    alt="avatar"
                  />
                </div>

                <p className="profileSideBarName">  {localStorage.getItem("firstName") ? localStorage.getItem("firstName")
                  : ''} {localStorage.getItem("lastName") ? localStorage.getItem("lastName")
                    : ''}</p>
                <CListGroup >

                  <NavLink to="/my-profile/info" className="mainTabWithSub" activeClassName="profileSideBarSelected"
                    onClick={() => { setshowSub(false); setshowMenu(false) }}><svg class="svg-icon" viewBox="0 0 20 20">
                      <path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
                    </svg>{t("my_profile")}
                  </NavLink>
                  <NavLink to="/my-profile/my-items" className="mainTabWithSub" activeClassName="profileSideBarSelected"
                    onClick={() => { setshowSub(false); setshowMenu(false) }}><svg class="svg-icon" viewBox="0 0 20 20">
                      <path d="M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385"></path>
                    </svg>{t("my_courses")}
                  </NavLink>

                  <div className={showMenu ? 'showProfileDropDown' : 'hideProfileDropDown'}>

                    {/* <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/interest" className="mainTabWithSub" activeClassName="profileSideBarSelected">Centre d'intérêt</NavLink>
                    <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/support" className="mainTabWithSub" activeClassName="profileSideBarSelected">Support</NavLink> */}
                    <NavLink onClick={() => { setshowSub(false); }} to="/my-profile/favorite" className="mainTabWithSub"
                      activeClassName="profileSideBarSelected"><svg class="svg-icon" viewBox="0 0 20 20">
                        <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073 M6.388,3.61C5.379,3.61,4.431,4,3.717,4.707C2.495,5.92,2.259,7.794,3.145,9.265c0.158,0.265,0.351,0.51,0.574,0.731L10,16.228l6.281-6.232c0.224-0.221,0.416-0.466,0.573-0.729c0.887-1.472,0.651-3.346-0.571-4.56C15.57,4,14.621,3.61,13.612,3.61c-1.43,0-2.639,0.786-3.268,1.863c-0.154,0.264-0.536,0.264-0.69,0C9.029,4.397,7.82,3.61,6.388,3.61"></path>
                      </svg>{t("my_favorites")}</NavLink>

                    <CListGroupItem className='profileSideBarLogout'>
                      <Link onClick={() => handleLogout()}
                        to="/" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>{t("logout")}</Link>
                    </CListGroupItem>
                  </div>
                </CListGroup>
              </CCol>
              <CCol sm="10" style={{ paddingRight: '5px', borderRadius: '0px 10px 10px 0px', background: '#e0f5f733' }} id="profileSectionContentID">

                <Switch>
                  <Route
                    path="/my-profile/info"
                    component={MyProfile}
                  />
                  <Route
                    path="/my-profile/my-items"
                    component={MyItems}
                  />
                  <Route path="/my-profile/favorite"
                    component={favorite}
                  />
                  <Route component={MyProfileMain} />
                </Switch>

              </CCol>
            </CRow>

          </React.Suspense>

          {/* </HashRouter> */}
        </div>
      </main >
    </div >
  );
}

export default Profile;
