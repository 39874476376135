import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from "react-i18next";
import FavoriteContext from '../FavoriteContext';

const About = () => {
  const { t } = useTranslation();
  const [Banner, setBanner] = useState(null);
  const [Bannerfr, setBannerfr] = useState(null);
  const [Bannermob, setBannermob] = useState(null);
  const [Bannermobfr, setBannermobfr] = useState(null);
  const { sliders } = useContext(FavoriteContext);
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust the width as needed
    };

    // Check the device on initial load
    checkMobile();

    // Add event listener to check when window size changes
    window.addEventListener('resize', checkMobile);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const getImageSrc = () => {
      const slider =
        currentLanguage === 'fr' && isMobile && Bannermobfr
          ? Bannermobfr
          : currentLanguage === 'fr' && !isMobile && Bannerfr
            ? Bannerfr
            : currentLanguage === 'en' && isMobile && Bannermob
              ? Bannermob
              : Banner
                ? Banner : '';

      return slider ? slider : '';
    };

    setImageSrc(getImageSrc());
  }, [currentLanguage, isMobile, Banner, Bannerfr, Bannermob, Bannermobfr]);


  useEffect(() => {
    console.log('rtdrtf', sliders, sliders.find(image => image.position === 'about'))
    if (sliders) {
      console.log('yyyyy', sliders)
      if (sliders.find(image => image.position === 'about')) setBanner('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'about').path)
      if (sliders.find(image => image.position === 'about-fr')) setBannerfr('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'about-fr').path)
      if (sliders.find(image => image.position === 'about-mob')) setBannermob('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'about-mob').path)
      if (sliders.find(image => image.position === 'about-mob-fr')) setBannermobfr('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'about-mob-fr').path)
    } else console.log('nnnnnoooo')
  }, [sliders]);

  return (
    <>
      <section className="presentation_page" >
        {imageSrc && <div className='fullBanner fullBanner2'><img src={imageSrc} alt='gdtc about banner' /></div>}

        <strong>Introduction</strong>
        <p>{t("about_1")}</p>
        <strong>{t("about_2")}</strong>
        <p>{t("about_3")}</p>
        <strong>{t("about_4")}</strong>
        <p>{t("about_5")}</p>
        <strong>{t("about_6")}</strong>
        <p>{t("about_7")}</p>
        <strong>{t("about_8")}</strong>
        <ul>
          <li><strong>{t("about_9")}</strong>{t("about_10")}</li>
          <li><strong>{t("about_11")}</strong>{t("about_12")}</li>
          <li><strong>{t("about_13")}</strong>{t("about_14")}</li>
        </ul>
        <strong>{t("about_15")}</strong>
        <p>{t("about_16")}</p>
        <strong>Conclusion</strong>
        <p>{t("about_17")}</p>


      </section>
    </>
  )
}

export default About
