import React, { useState, useEffect } from 'react'
import { isLogin } from "src/components/middleware/auth";

const MyProfileMain = (props) => {



  return (
    <img style={{ margin: 'auto', display: 'block', borderRadius: '8px' }} src="/assets/images/profile-mainplein-de-couleur.jpeg" alt="student dashboard banner" />

  );
}

export default MyProfileMain;
