import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
const Certificats = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [imageSrc, setImageSrc] = useState('');
  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    const getImageSrc = () => {
      const slider =
        currentLanguage === 'fr'
          ? '/assets/images/banner-certificate-francais.jpg'
          : '/assets/images/banner-certificate-english.jpg';

      return slider;
    };

    setImageSrc(getImageSrc());
  }, [currentLanguage]);
  return (
    <>
      <section className="presentation_page" >
        {imageSrc && <div className='fullBanner fullBanner2'><img src={imageSrc} alt='gdtc about banner' /></div>}

        <strong>{t("certificats_16")}</strong>
        <p>{t("certificats_1")}</p>
        <strong>{t("certificats_2")}</strong>
        <p>{t("certificats_3")}</p>
        <strong>{t("certificats_4")}</strong>
        <p>{t("certificats_5")}</p>
        <strong>{t("certificats_6")}</strong>
        <p>{t("certificats_7")}</p>
        <strong>{t("certificats_8")}</strong>
        <ul>
          <li><strong>{t("certificats_9")}</strong>{t("certificats_10")}</li>
          <li><strong>{t("certificats_11")}</strong>{t("certificats_12")}</li>
          <li><strong>{t("certificats_13")}</strong>{t("certificats_14")}</li>
        </ul>

        <p>{t("certificats_15")}</p>



      </section>
    </>
  )
}

export default Certificats
