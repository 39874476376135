import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL, IMAGES_BASE_URL_, consol } from "src/config";
import HtmlParser from "react-html-parser";
import { CSpinner } from "@coreui/react";

const Blog = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const [blog, setBlog] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        console.log('locat', location)
        if (location.state) {
            setIsLoaded(true);

            const data = location.state
            console.log('with state', data)
            setBlog(data)

        } else {
            axios(`${API_BASE_URL}/blog/show/${id}`)
                .then((res) => res.data)
                .then(
                    (result) => {
                        consol('hfsjhfjsjjjjjjj', result)
                        setBlog(result)
                        setIsLoaded(true);
                    },
                    (error) => {
                        console.log(error);
                        setError(error);
                    }
                );
        }
    }, [id]);

    if (error) {
        return <div className="error-message">{error.message}</div>;
    } else if (!isLoaded) {
        return <div className='loadingMain'>
            <CSpinner color="default" size="sm" />
        </div>
            ;
    } else
        return (
            <>
                {blog && <article className="single-blog-container">
                    <header className="blog-header">
                        <h1 className="blog-title">{blog.title}</h1>
                        <div className="blog-meta">
                            <span className="publish-date">
                                Published on {new Date(blog.createdAt).toLocaleDateString()}
                            </span>
                        </div>
                    </header>

                    <div className="featured-image">
                        <img
                            src={IMAGES_BASE_URL_ + 'blog/' + blog.path}
                            alt={blog.title}
                            className="blog-main-image"
                        />
                    </div>

                    <section className="blog-content">
                        {HtmlParser(decodeURIComponent(blog.content))}
                    </section>

                    <footer className="blog-footer">
                        <div className="tags-section">
                            {blog.tags?.map(tag => (
                                <span key={tag} className="blog-tag">{tag}</span>
                            ))}
                        </div>
                    </footer>
                </article>}
            </>
        );
};

export default Blog;
