import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL_U } from "../../../config";
import axios from 'axios'
import { CCard, CCardBody, CCardFooter, CSpinner } from "@coreui/react";
import { Link } from "react-router-dom";
import TrainingCard from "./TrainingCard";
import { useTranslation } from "react-i18next";

const Trainings = (props) => {
    const [formations, setFormations] = useState([]);
    const [Banner, setBanner] = useState(null);
    const [Bannerfr, setBannerfr] = useState(null);
    const [Bannermob, setBannermob] = useState(null);
    const [Bannermobfr, setBannermobfr] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { id } = props.match.params;
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768); // You can adjust the width as needed
        };

        // Check the device on initial load
        checkMobile();

        // Add event listener to check when window size changes
        window.addEventListener('resize', checkMobile);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const getImageSrc = () => {
            const slider =
                currentLanguage === 'fr' && isMobile && Bannermobfr
                    ? Bannermobfr
                    : currentLanguage === 'fr' && !isMobile && Bannerfr
                        ? Bannerfr
                        : currentLanguage === 'en' && isMobile && Bannermob
                            ? Bannermob
                            : Banner
                                ? Banner : '';

            return slider ? slider : '';
        };

        setImageSrc(getImageSrc());
    }, [currentLanguage, isMobile, Banner, Bannerfr, Bannermob, Bannermobfr]);

    useEffect(() => {
        setIsLoaded(false)
        axios(`${API_BASE_URL}/trainings/${id}`)
            .then((res) => res.data)
            .then(
                (result) => {
                    console.log('tttrrrr', result);
                    setFormations(result.trainings);
                    if (result.banner) setBanner(IMAGES_BASE_URL_U + result.banner)
                    if (result.bannerfr) setBannerfr(IMAGES_BASE_URL_U + result.bannerfr)
                    if (result.bannermob) setBannermob(IMAGES_BASE_URL_U + result.bannermob)
                    if (result.bannermobfr) setBannermobfr(IMAGES_BASE_URL_U + result.bannermobfr)
                    setIsLoaded(true);
                    console.log(result)
                },
                (error) => {
                    console.log(error);
                    setError(error)
                }
            );
        //request banner from backend
        console.log('ggggggggg');
        //console.log(banners.find(item => item.category == id));
        //setBanner(banners.find(item => item.category == id).path);
    }, [id]);

    if (error) {
        return (
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
                <div className="row">
                    {error.message}
                </div>
            </div>
        );
    }
    // else 
    // if (!isLoaded) {
    //     return (
    //         <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
    //             <div className="row">
    //             <h2 className=" text-center heading">COURSES</h2>
    //             <div className='loadingMain'>
    //   <CSpinner color="default" size="sm" />
    // </div>
    //             </div>
    //             </div>
    //     );
    // } 
    else {

        return (
            <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%", marginTop: "2" }}>
                <div className="row">
                    {imageSrc && <div className='fullBanner fullBanner2'><img src={imageSrc} alt='gdtc banner' /></div>}

                    <h2 className=" text-center heading">COURSES</h2>

                    {isLoaded && formations.length > 0 ? formations.map((formation, index) => {
                        return (
                            <div className="col-md-3" key={index} style={{ marginBottom: '20px', borderRadius: '50px' }} >
                                <TrainingCard
                                    path={formation.demo && formation.demo}
                                    image={formation.imageTrainings && formation.imageTrainings.length > 0 ? formation.imageTrainings[0].path : ''}
                                    title={formation.title}
                                    formateur={formation.trainer ?
                                        formation.trainer.firstName && formation.trainer.firstName + " " + formation.trainer.lastName
                                        : ''}
                                    createdAt={formation.createdAt}
                                    updatedAt={formation.updatedAt}
                                    sessions={formation.sessions}
                                    id={formation.id}
                                    language={formation.language}
                                    medical={false}
                                >

                                </TrainingCard>
                            </div>

                        )
                    })
                        : isLoaded && formations.length < 1 ?
                            "no course here yet"
                            : <div className='loadingMain'>
                                <CSpinner color="default" size="sm" />
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default Trainings
