import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from "react-i18next";
import FavoriteContext from '../FavoriteContext';

const Staff = () => {
    const { t } = useTranslation();
    const [Banner, setBanner] = useState(null);
    const [Bannerfr, setBannerfr] = useState(null);
    const [Bannermob, setBannermob] = useState(null);
    const [Bannermobfr, setBannermobfr] = useState(null);
    const { sliders } = useContext(FavoriteContext);
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768); // You can adjust the width as needed
        };

        // Check the device on initial load
        checkMobile();

        // Add event listener to check when window size changes
        window.addEventListener('resize', checkMobile);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const getImageSrc = () => {
            const slider =
                currentLanguage === 'fr' && isMobile && Bannermobfr
                    ? Bannermobfr
                    : currentLanguage === 'fr' && !isMobile && Bannerfr
                        ? Bannerfr
                        : currentLanguage === 'en' && isMobile && Bannermob
                            ? Bannermob
                            : Banner
                                ? Banner : '';

            return slider ? slider : '';
        };

        setImageSrc(getImageSrc());
    }, [currentLanguage, isMobile, Banner, Bannerfr, Bannermob, Bannermobfr]);


    useEffect(() => {
        console.log('rtdrtf', sliders, sliders.find(image => image.position === 'team'))
        if (sliders) {
            console.log('yyyyy', sliders)
            if (sliders.find(image => image.position === 'team')) setBanner('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'team').path)
            if (sliders.find(image => image.position === 'team-fr')) setBannerfr('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'team-fr').path)
            if (sliders.find(image => image.position === 'team-mob')) setBannermob('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'team-mob').path)
            if (sliders.find(image => image.position === 'team-mob-fr')) setBannermobfr('https://gdtc.ac/public/uploads/sliders/' + sliders.find(image => image.position === 'team-mob-fr').path)
        } else console.log('nnnnnoooo')
    }, [sliders]);

    return (
        <>
            <section className="presentation_page" >
                {imageSrc && <div className='fullBanner fullBanner2'><img src={imageSrc} alt='gdtc team banner' /></div>}
                <strong>{t("staff_1")}</strong>
                <p>{t("staff_2")}</p>




            </section>
        </>
    )
}

export default Staff
