import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, IMAGES_BASE_URL_, consol } from "src/config";
import HtmlParser from "react-html-parser";
import { CSpinner } from "@coreui/react";
import { Link } from "react-router-dom";

const Blogs = (props) => {
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(false);
        axios(`${API_BASE_URL}/publishedBlogs`)
            .then((res) => res.data)
            .then(
                (result) => {
                    consol('hfsjhfjsjjjjjjj', result)
                    setResults(result);
                    setIsLoaded(true);
                },
                (error) => {
                    console.log(error);
                    setError(error);
                }
            );
    }, []);

    const decodeContent = (content) => {
        try {
            // First decode the URI component
            const decoded = decodeURIComponent(content);
            // Then replace the escaped single quotes
            return decoded.replace(/\\'/g, "'");
        } catch (e) {
            console.error('Failed to decode content:', e);
            return content.replace(/\\'/g, "'"); // Fallback: just handle the quotes
        }
    };

    if (error) {
        return <div className="error-message">{error.message}</div>;
    } else if (!isLoaded) {
        return <div className='loadingMain'>
            <CSpinner color="default" size="sm" />
        </div>
            ;
    } else {


        return (
            <>
                <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%", marginTop: "2" }}>

                    <br />   <h2 className=" text-center heading">Blog</h2>


                    <div className="blog-container">
                        <div className="blog-grid">
                            {results.map((blog, index) => {
                                const rowIndex = Math.floor(index / 3);
                                const isEvenRow = rowIndex % 2 === 0;
                                const isTall = index % 3 === 0;
                                let gridColumn, gridRow;

                                if (isTall) {
                                    gridColumn = isEvenRow ? 1 : 2;
                                    gridRow = `${rowIndex * 2 + 1} / span 2`;
                                } else {
                                    gridColumn = isEvenRow ? 2 : 1;
                                    const positionInTrio = index % 3;
                                    gridRow = rowIndex * 2 + (positionInTrio === 1 ? 1 : 2);
                                }

                                return (
                                    <Link
                                        key={blog.id}
                                        to={{
                                            pathname: `/blog/${blog.id}`,
                                            state: blog,
                                        }}
                                        className={`blog-card ${isTall ? 'blog-tall' : 'blog-short'}`}
                                        style={{
                                            gridColumn,
                                            gridRow,
                                        }}
                                    >
                                        <img src={IMAGES_BASE_URL_ + 'blog/' + blog.path} alt={blog.title} className="blog-image" />
                                        <div className="blog-overlay">
                                            <h2 className="blog-title">{blog.title}</h2>

                                            <div className="content-preview content-preview2">
                                                {HtmlParser(decodeURIComponent(blog.content).split('\n').slice(0, 4).join('\n'))}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>


                </div>
            </>
        );
    }
};

export default Blogs;