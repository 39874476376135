import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import * as actions from '../../../reducers/actions';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { API_BASE_URL, MEDIA_URL } from "../../../config";
import { CAlert, CFormCheck, CSpinner, CButton } from '@coreui/react'
import { Link } from 'react-router-dom';
import imageCompression from "browser-image-compression";


const Register = (props) => {
    const { t } = useTranslation();
    const [gender, setGender] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [submittedButton, setSubmittedButton] = useState(false);
    const [lastName, setLasName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [profession, setProfession] = useState("")
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [address, setAddress] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otherPhone, setOtherPhone] = useState("");
    const [accept, setAccept] = useState(false);
    const [adherant, setAdherant] = useState(false);
    const [error, setError] = useState(false);
    const [errorCaptcha, setErrorCaptcha] = useState(false);
    const [success, setSuccess] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [profileThumb, setprofileThumb] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [lang, setLang] = useState('en');
    const { i18n } = useTranslation();
    function changeLanguage(lang) {

        i18n.changeLanguage(lang);
        setLang(lang)
    }

    const handleFileSection = async (event, index) => {
        setUploadingImage(true);
        console.log('images at begining', event.target.files[0]);

        let file = event.target.files[0];

        console.log("file before");
        console.log(file);

        let options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
        };
        try {
            file = await imageCompression(file, options);
            console.log("file after");
            console.log(file);
        } catch (error) {
            console.log(error);
        }

        setprofileThumb(URL.createObjectURL(file));
        setProfileImage(file);
        setUploadingImage(false);

        setTimeout(() => {
            console.log('image at end', profileImage);
        }, 300);
    };

    const validEmailRegex = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const sanitizeInput = (input) => {
        return input.replace(/[^a-zA-Z0-9\s'-]/g, '');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log('prof: ', profileImage);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6Lf54OcqAAAAAHvcilmH6fNPTto5lc3B9nFJSyyY', { action: 'submit' }).then(token => {
                //console.log('statexxxxxxxxx : ', token);
                if (token && (validEmailRegex.test(email) && lastName && firstName && (password && password.length > 5) && password == passwordConfirmation && country && city && phoneNumber)) {
                    {
                        setSubmittedButton(true);
                        if (profileImage) {
                            const formData2 = new FormData();
                            formData2.append('security_key', "b46e1d5b87d3a97c0a22bc7c37b1c0e74e0e2d0a08e1b1fdd1fe8a6e23f53b491010");
                            formData2.append('profile', profileImage);
                            let title = firstName + '_' + lastName;
                            formData2.append("title", title);
                            formData2.append("member", '1');
                            console.log('trainer', typeof formData2.get("trainer"), formData2.get("trainer"));
                            console.log(formData2.get("profile"));

                            axios.post(`${MEDIA_URL}upload.php`, formData2)
                                .then((res) => {
                                    console.log('res :');
                                    console.log(res);
                                    if (res.status != 200) {
                                        setSubmittedButton(false);
                                        alert('un error occured !');
                                    } else {
                                        const formData = new FormData();
                                        formData.append("gender", gender);
                                        formData.append("firstName", firstName);
                                        formData.append("lastName", lastName);
                                        formData.append("profession", profession);
                                        formData.append("email", email);
                                        formData.append("password", password);
                                        formData.append("address", address);
                                        formData.append("zipCode", zipCode);
                                        formData.append("country", country);
                                        formData.append("city", city);
                                        formData.append("phoneNumber", phoneNumber);
                                        formData.append("otherPhone", otherPhone);
                                        formData.append("adherant", adherant);
                                        formData.append('captchaToken', token);
                                        formData.append("profile", res.data.profile[0]);
                                        axios
                                            .post(`${API_BASE_URL}/members/register`, formData)
                                            .then((response) => {

                                                // localStorage.setItem("token", response.data.token);
                                                // localStorage.setItem("username", response.data.user.name);
                                                // useHistory.push('/posts');
                                                console.log(response);
                                                if (response.data.status === 'The email provided already has an account!') {
                                                    setError(true);
                                                    setSubmittedButton(false);
                                                } else if (response.status == 200) {
                                                    setError(false)
                                                    setSuccess(true);
                                                    setSubmitted(false);
                                                    setSubmittedButton(false);

                                                } else {
                                                    setSubmitted(false);
                                                    setSubmittedButton(false);

                                                }
                                            })
                                            .catch((response) => {

                                                console.log(response);
                                                setSubmittedButton(false);
                                            });
                                    }
                                })
                                .catch((res) => {
                                    console.log('error: ', res);
                                    setSubmittedButton(false);
                                    alert('un error occured !');
                                });
                        }
                        else {
                            const formData = new FormData();
                            formData.append("gender", gender);
                            formData.append("firstName", sanitizeInput(firstName));
                            formData.append("lastName", sanitizeInput(lastName));
                            formData.append("profession", sanitizeInput(profession));
                            formData.append("email", email);
                            formData.append("password", password);
                            formData.append("address", address);
                            formData.append("zipCode", zipCode);
                            formData.append("country", country);
                            formData.append("city", city);
                            formData.append("phoneNumber", phoneNumber);
                            formData.append("otherPhone", otherPhone);
                            formData.append("adherant", adherant);
                            formData.append('captchaToken', token);
                            axios
                                .post(`${API_BASE_URL}/members/register`, formData)
                                .then((response) => {

                                    // localStorage.setItem("token", response.data.token);
                                    // localStorage.setItem("username", response.data.user.name);
                                    // useHistory.push('/posts');
                                    console.log(response);
                                    if (response.data.status && response.data.status.toString() == "captcha-failed") {
                                        setErrorCaptcha(true);
                                        setSubmittedButton(false);
                                    }
                                    else if (response.data.status && (response.data.status[0] === 'The email provided already has an account!' || response.data.status === 'The email provided already has an account!')) {
                                        setError(true);
                                        setSubmittedButton(false);
                                    } else {
                                        setError(false)
                                        setSuccess(true);
                                        setSubmitted(false);
                                        setSubmittedButton(false);
                                        // setGender("");
                                        // setFirstName("");
                                        // setLasName("");
                                        // setProfession("");
                                        // setEmail("");
                                        // setPassword("");
                                        // setPasswordConfirmation("");
                                        // setAddress("");
                                        // setCity("");
                                        // setCountry("");
                                        // setZipCode("");
                                        // setPhoneNumber("");
                                        // setOtherPhone("");
                                        // setAccept("");
                                        // setAddress("");


                                    }
                                })
                                .catch((response) => {

                                    console.log(response);
                                    setSubmittedButton(false);
                                    /*   if (
                                           response.data.errors.email.toString() ===
                                           "The email has already been taken."
                                       ) {
                                           authError("The email has already been taken.");
                                       }*/
                                });
                        }


                    }

                } else {
                    window.scrollTo({ top: 150, behavior: "smooth" });
                    setSubmitted(false); setSubmittedButton(false);
                }
            });
        });


    }

    if (success)
        return (
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ textAlign: "center", "marginBottom": "10%", "padding": "15px", "borderRadius": "4px" }}>

                <div className="loading_logo">
                    <br />
                    <img
                        src={"/assets/icons/success.png"}
                        alt="success icon"
                    /><br /><br />
                    <p style={{ color: "#1c9070", textAlign: "center", fontSize: "22px" }}>
                        {t("account_created")}
                    </p>
                </div>

            </div>
        );
    else return (
        <>
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%", "padding": "15px", "borderRadius": "4px" }}>
                <div className='tempHeadingForRegister' style={{ marginTop: '-5%', marginBottom: '25px' }} >

                    <div className='desktopHeader' >
                        <nav >
                            <div className="w_mobilebgcover"></div>
                            <div className="w_nav">
                                <div data-collapse="medium" data-animation="default" data-duration="500" data-doc-height="1"
                                    data-w-id="392b72ab-2256-dd42-b8fd-e4ecdc60f83b" className="n_mainmobilenavbar w-nav">
                                    <div className="c_navbar_mobile w-container">
                                        {<Link to="#" aria-current="page"
                                            className="logo w-nav-brand w--current"><img
                                                src="/assets/images/logo_gdtc.png"
                                                alt="GDTC Logo " className="img_navlogo"
                                                style={{ position: 'relative', top: '-15px', height: '70px' }}
                                            />
                                        </Link>
                                        }

                                        <nav role="navigation" className="nav-menu w-nav-menu" id="nav"  >
                                            <div className="d_navleftside">
                                            </div>
                                            <div className="nav_menu_content">

                                            </div>

                                            <div className="d_mobilenavdivider"></div>
                                            <div className="d_navrightside">

                                                <div className='changeLang'>
                                                    <span onClick={(e) => changeLanguage('fr')}
                                                        className={`${lang == 'en' ? 'showLangFlag' : 'hideLangFlag'}  tl_nav_link cc_smallermargin cc_navmediulink `}>
                                                        Français  <img src={"/assets/images/fr.png"} className="flag" /></span>
                                                    <span onClick={(e) => changeLanguage('en')}
                                                        className={`${lang == 'fr' ? 'showLangFlag' : 'hideLangFlag'} tl_nav_link cc_smallermargin cc_navmediulink`}>
                                                        English <img src={"/assets/images/en.png"} className="flag" /></span>

                                                </div>


                                                <div data-w-id="515a474a-883e-6da8-0b48-9895f0629a36" className="nav_mobile"></div>
                                            </div>

                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <h4>{t("Merci_renseigner")}</h4>
                <h3>{t("your_information")}</h3>


                <form onSubmit={handleSubmit}>


                    <div className="fsRowBody">

                        <CFormCheck
                            type="radio"
                            inline
                            name="gender"
                            id="Mr"
                            label="Mr"
                            value="Mr"
                            onChange={(e) => setGender(e.target.value)}
                        />
                        <CFormCheck
                            type="radio"
                            inline
                            name="gender"
                            id="Mme"
                            label="Mme"
                            value="Mme"
                            onChange={(e) => setGender(e.target.value)}
                        />
                        {submitted && !gender && (
                            <span className="invalid-feedback error">
                                {t("required")}
                            </span>
                        )}
                    </div>
                    <div className="w-layout-grid ">



                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="lastName">{t("nom")}
                                <span className="fsRequiredMarker">*</span> </label>
                            <input type="text" className="form-control" name="lastName" value={lastName} onChange={(e) => setLasName(e.target.value)}></input>
                            {submitted && !lastName && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}
                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="firstName">{t("prenom")}
                                <span
                                    className="fsRequiredMarker">*</span> </label>
                            <input type="text" className="form-control" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                            {submitted && !firstName && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}

                        </div>
                        {/* 
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="profession">{t("specialty")}<span
                                className="fsRequiredMarker">*</span> </label>

                            <input type="text" className="form-control" name="profession" value={profession} onChange={(e) => setProfession(e.target.value)}></input>
                            {submitted && !profession && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}
                        </div> */}



                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="email">E-mail<span
                                className="fsRequiredMarker">*</span> </label>
                            <input type="text" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                            {submitted && !validEmailRegex.test(email) && (
                                <span className="invalid-feedback error">
                                    Invalid email
                                </span>
                            )}
                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="password">{t("password")}<span
                                className="fsRequiredMarker">*</span> </label>
                            <input type="password" className="form-control" name="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                            {submitted && !password && (
                                <span className="invalid-feedback error">{t("required")}</span>
                            )}
                            {submitted && (password && password.length < 6) && (
                                <span className="invalid-feedback error">{t("password_6chars")}</span>
                            )}

                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="password">{t("confirm_password")}<span
                                className="fsRequiredMarker">*</span> </label>
                            <input type="password" className="form-control"
                                name="password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}></input>
                            {submitted && passwordConfirmation !== password && (
                                <span className="invalid-feedback error">
                                    {t("password_not_confirmed")}
                                </span>
                            )}
                        </div>


                    </div>
                    <h3>{t("contact_detail")}</h3>
                    {/* <div className="fsRowBody">
                        <label className="fsLabel fsRequiredLabel" htmlFor="address">{t('adresse')}<span
                            className="fsRequiredMarker">*</span> </label>
                        <input type="text" className="form-control" name="address" value={address} onChange={(e) => setAddress(e.target.value)}></input>
                        {submitted && !address && (
                            <span className="invalid-feedback error">
                                {t("required")}
                            </span>
                        )}
                    </div>
                    <div className="w-layout-grid ">
                        <div>
                            <div className="fsRowBody">
                                <label className="fsLabel fsRequiredLabel"
                                    htmlFor="zipCode">{t("code_postal")}<span
                                        className="fsRequiredMarker">*</span> </label>
                                <input type="text" className="form-control" name="zipCode" value={zipCode} onChange={(e) => setZipCode(e.target.value)}></input>
                                {submitted && !zipCode && (
                                    <span className="invalid-feedback error">
                                        {t("required")}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div> */}
                    <div className="w-layout-grid ">

                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="country">{t("pays")}<span
                                className="fsRequiredMarker">*</span> </label>
                            <CountryDropdown
                                value={country}
                                className="form-control"
                                defaultOptionLabel="Choisissez un pays"
                                onChange={(val) => setCountry(val)}
                                priorityOptions={["TN"]}
                                blacklist={['IL']}
                            />
                            {submitted && !country && (
                                <span className="invalid-feedback error">{t("required")}</span>
                            )}
                        </div>
                        <div>
                            <div className="fsRowBody">
                                <label className="fsLabel fsRequiredLabel" htmlFor="city">{t("ville")}<span
                                    className="fsRequiredMarker">*</span> </label>
                                <RegionDropdown
                                    country={country}
                                    value={city}
                                    className="form-control"
                                    onChange={(val) => setCity(val)}
                                />
                                {submitted && !city && (
                                    <span className="invalid-feedback error">{t("required")}</span>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="w-layout-grid ">
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel"
                                htmlFor="phoneNumber">{t("mobile_phone")}<span
                                    className="fsRequiredMarker">*</span> </label>
                            <PhoneInput
                                type="text"
                                onChange={setPhoneNumber}
                                placeholder="Enter phone number"
                                defaultCountry="TN"
                                international
                                name="phoneNumber"
                                id="phoneNumber"
                                value={phoneNumber}

                            />
                            {submitted && !isValidPhoneNumber(phoneNumber) && (
                                <span className="invalid-feedback error">{t("required")}</span>
                            )}
                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="otherPhone">{t("autre_phone")}
                                <span
                                    className="sp_beige_text"> ({t("facultatif")})</span> </label>
                            <PhoneInput
                                type="text"
                                onChange={setOtherPhone}
                                placeholder="Enter phone number"
                                defaultCountry="TN"
                                international
                                name="otherPhone"
                                id="otherPhone"
                                value={otherPhone}

                            />
                        </div>
                        {/* <div className="form-group">
                            <label className="fsLabel fsRequiredLabel" htmlFor="image">Profile image </label>
                            <div style={{ height: "90px" }}>
                                <input className="form-control"
                                    style={{ display: "inline-block", width: "70%" }}
                                    type="file"
                                    name="image"
                                    onChange={() => handleFileSection(event, 0)}
                                    //accept=".jpg,.png,jpeg"
                                    accept="image/*"
                                ></input>
                                {uploadingImage && <span style={{ height: "fit-content", marginLeft: "10px", lineHeight: "90px" }}>
                                    {" "}
                                    <CSpinner color="default" size="sm" />
                                </span>}
                                {!uploadingImage && profileThumb && <img style={{ float: "right", marginTop: "-7px", height: "85px", width: "auto" }}
                                    src={profileThumb}
                                    alt="preview"
                                    className="img-fluid img-thumbnail "
                                />}
                            </div>
                        </div> */}
                    </div>
                    <label htmlFor="accept" className="required"> <input
                        name="accept"
                        type="checkbox"
                        checked={accept}
                        onChange={(e) => setAccept(e.target.checked)} /> {t("accept_use_conditions")}</label>

                    <Link to="/mentions-legales">{t("legal_notices")}</Link> <br />
                    {submitted && !accept && (
                        <span className="invalid-feedback error">{t("required")}</span>
                    )}
                    <br />
                    <label htmlFor="adherant" className="required"> <input
                        name="adherant"
                        type="checkbox"
                        checked={adherant}
                        onChange={(e) => setAdherant(e.target.checked)} />  {t("wish_newsletter")}</label>

                    <br />


                    {submitted && errorCaptcha && (
                        <CAlert color="danger" >
                            Google Recaptcha could not verify that you are not a robot, please try again
                        </CAlert>
                    )}
                    {submitted && error && (
                        <CAlert color="danger" >
                            {t("email_already")}
                        </CAlert>
                    )}

                    {submitted && success && (<CAlert color="success">
                        {/* {t("gdtc_create_check_mail")} */}
                        Your GDTC account has just been created Please check your email for confirmation code
                    </CAlert>)}
                    <div className="w-layout-grid ">
                        {/* <input disabled={submitted} id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                         type="submit" value={t("creer_compte_submit")} /> */}
                        <CButton className="btn_black w-button btn-block" type="submit"
                            disabled={submittedButton} > {submittedButton && <CSpinner color="default" size="sm" />} {t("creer_compte_submit")}</CButton>
                    </div>

                </form>
            </div>
        </>
    )
}

export default Register
